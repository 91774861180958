import awan from "../img/c1d.png";
import awan1 from "../img/c2d.png";
import ambulance from "../img/mobil 4d.png";
import bus from "../img/busd.png";
import bis from "../img/bisd.png";
import pickup from "../img/pickupd.png";
import jembatan1 from "../img/BRIDGE LAYER 11d.png";
import ojol1 from "../img/ojol1d.png";
import ojol2 from "../img/ojol2d.png";
import rewako from "../img/rewakod.png";
import jembatan2 from "../img/BRIDGE LAYER 1d.png";
import Web from "../dropdown/Web";
import Smartcity from "../dropdown/Smartcity";
// import leader from "../img/L.png";
// import leaderr from "../img/LL.png";
import welcome from "../img/welcom.png";

const Layout = () => {
  return (
    <div>
      <div className="container-fluid">
        <div>
          <img src={awan} className="awan" alt="awan" />
          <img src={awan1} className="awan1" alt="awan1" />
          <img src={welcome} className="welcome" alt="welcome" />
          <img src={ambulance} className="ambulance" alt="ambulance" />
          <img src={pickup} className="pickup" alt="pickup" />
          <img src={bus} className="bus" alt="bus" />
          <img src={jembatan1} className="j1" alt="jembatan1" />
          <img src={ojol1} className="ojol1" alt="ojol1" />
          <img src={ojol2} className="ojol2" alt="ojol2" />
          <img src={rewako} className="rewako" alt="rewako" />
          <img src={bis} className="bis" alt="bis" />
          <img src={jembatan2} className="j2" alt="jembatan2" />
          {/* <img src={leaderr} className="leaderr" alt="leaderr" />
          <img src={leader} className="leader" alt="leader" /> */}
        </div>

        <Smartcity />
        <Web />
      </div>
    </div>
  );
};

export default Layout;
